
@font-face {
    font-family: 'Neutra Text';
    src: url('../../fonts/NeutraText-Bold.otf');
    font-weight: 700;
    font-style: normal;

}

@font-face {
    font-family: 'Neutra Text';
    src: url('../../fonts/NeutraText-Demi.otf');
    font-weight: 500;
    font-style: normal;
}


@font-face {
    font-family: 'Lato';
    src: url('../../fonts/lato-black.eot'),
         url('../../fonts/lato-black.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/lato-black.woff2') format('woff2'),
         url('../../fonts/lato-black.woff') format('woff'),
         url('../../fonts/lato-black.ttf') format('truetype'),
         url('../../fonts/lato-black.svg#latoblack') format('svg');
    font-weight: 900;
    font-style: normal;

}

@font-face {
    font-family: 'Lato';
    src: url('../../fonts/lato-blackitalic.eot'),
         url('../../fonts/lato-blackitalic.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/lato-blackitalic.woff2') format('woff2'),
         url('../../fonts/lato-blackitalic.woff') format('woff'),
         url('../../fonts/lato-blackitalic.ttf') format('truetype'),
         url('../../fonts/lato-blackitalic.svg#latoblack_italic') format('svg');
    font-weight: 900;
    font-style: italic;

}

@font-face {
    font-family: 'Lato';
    src: url('../../fonts/lato-bold.woff2') format('woff2'),
         url('../../fonts/lato-bold.woff') format('woff'),
         url('../../fonts/lato-bold.ttf') format('truetype'),
         url('../../fonts/lato-bold.svg#latobold') format('svg');
    font-weight: 700;
    font-style: normal;

}
@font-face {
    font-family: 'Lato';
    src: url('../../fonts/lato-bolditalic.woff2') format('woff2'),
         url('../../fonts/lato-bolditalic.woff') format('woff'),
         url('../../fonts/lato-bolditalic.ttf') format('truetype'),
         url('../../fonts/lato-bolditalic.svg#latobold_italic') format('svg');
    font-weight: 700;
    font-style: italic;

}

@font-face {
    font-family: 'Lato';
    src: url('../../fonts/lato-semibold.woff2') format('woff2'),
         url('../../fonts/lato-semibold.woff') format('woff'),
         url('../../fonts/lato-semibold.ttf') format('truetype'),
         url('../../fonts/lato-semibold.svg#latosemibold') format('svg');
    font-weight: 600;
    font-style: normal;

}

@font-face {
    font-family: 'Lato';
    src: url('../../fonts/lato-semibolditalic.woff2') format('woff2'),
         url('../../fonts/lato-semibolditalic.woff') format('woff'),
         url('../../fonts/lato-semibolditalic.ttf') format('truetype'),
         url('../../fonts/lato-semibolditalic.svg#latosemibold_italic') format('svg');
    font-weight: 600;
    font-style: italic;

}

@font-face {
    font-family: 'Lato';
    src: url('../../fonts/lato-medium.woff2') format('woff2'),
         url('../../fonts/lato-medium.woff') format('woff'),
         url('../../fonts/lato-medium.ttf') format('truetype'),
         url('../../fonts/lato-medium.svg#latomedium') format('svg');
    font-weight: 500;
    font-style: normal;

}

@font-face {
    font-family: 'Lato';
    src: url('../../fonts/lato-mediumitalic.woff2') format('woff2'),
         url('../../fonts/lato-mediumitalic.woff') format('woff'),
         url('../../fonts/lato-mediumitalic.ttf') format('truetype'),
         url('../../fonts/lato-mediumitalic.svg#latomedium_italic') format('svg');
    font-weight: 500;
    font-style: italic;

}

@font-face {
    font-family: 'Lato';
    src: url('../../fonts/lato-regular.woff2') format('woff2'),
         url('../../fonts/lato-regular.woff') format('woff'),
         url('../../fonts/lato-regular.ttf') format('truetype'),
         url('../../fonts/lato-regular.svg#latoregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Lato';
    src: url('../../fonts/lato-italic.woff2') format('woff2'),
         url('../../fonts/lato-italic.woff') format('woff'),
         url('../../fonts/lato-italic.ttf') format('truetype'),
         url('../../fonts/lato-italic.svg#latoitalic') format('svg');
    font-weight: normal;
    font-style: italic;

}

@font-face {
    font-family: 'Lato';
    src: url('../../fonts/lato-light.woff2') format('woff2'),
         url('../../fonts/lato-light.woff') format('woff'),
         url('../../fonts/lato-light.ttf') format('truetype'),
         url('../../fonts/lato-light.svg#latolight') format('svg');
    font-weight: 300;
    font-style: normal;

}

@font-face {
    font-family: 'Lato';
    src: url('../../fonts/lato-lightitalic.woff2') format('woff2'),
         url('../../fonts/lato-lightitalic.woff') format('woff'),
         url('../../fonts/lato-lightitalic.ttf') format('truetype'),
         url('../../fonts/lato-lightitalic.svg#latolight_italic') format('svg');
    font-weight: 300;
    font-style: italic;

}
