/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "bootstrap-4.3.1/scss/functions";
/*@import "bootstrap-4.3.1/scss/variables";*/
@import "variables_bootstrap";
@import "bootstrap-4.3.1/scss/mixins";
@import "bootstrap-4.3.1/scss/root";
@import "bootstrap-4.3.1/scss/reboot";
@import "bootstrap-4.3.1/scss/type";
@import "bootstrap-4.3.1/scss/images";
@import "bootstrap-4.3.1/scss/code";
@import "bootstrap-4.3.1/scss/grid";
@import "bootstrap-4.3.1/scss/tables";
@import "bootstrap-4.3.1/scss/forms";
@import "bootstrap-4.3.1/scss/buttons";
@import "bootstrap-4.3.1/scss/transitions";
@import "bootstrap-4.3.1/scss/dropdown";
@import "bootstrap-4.3.1/scss/button-group";
@import "bootstrap-4.3.1/scss/input-group";
@import "bootstrap-4.3.1/scss/custom-forms";
@import "bootstrap-4.3.1/scss/nav";
@import "bootstrap-4.3.1/scss/navbar";
@import "bootstrap-4.3.1/scss/card";
@import "bootstrap-4.3.1/scss/breadcrumb";
@import "bootstrap-4.3.1/scss/pagination";
@import "bootstrap-4.3.1/scss/badge";
@import "bootstrap-4.3.1/scss/jumbotron";
@import "bootstrap-4.3.1/scss/alert";
@import "bootstrap-4.3.1/scss/progress";
@import "bootstrap-4.3.1/scss/media";
@import "bootstrap-4.3.1/scss/list-group";
@import "bootstrap-4.3.1/scss/close";
@import "bootstrap-4.3.1/scss/toasts";
@import "bootstrap-4.3.1/scss/modal";
@import "bootstrap-4.3.1/scss/tooltip";
@import "bootstrap-4.3.1/scss/popover";
@import "bootstrap-4.3.1/scss/carousel";
@import "bootstrap-4.3.1/scss/spinners";
@import "bootstrap-4.3.1/scss/utilities";
@import "bootstrap-4.3.1/scss/print";
