.popup-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 10001;
	padding: 100px 30px;
	background: #fff;
	/*display: none;*/
	overflow: auto;
}
.popup-content {
	max-width: 1364px;
	width: 100%;
	margin: auto;
	background: #fff;
	position: relative;
	z-index: 2;
}
.popup-header {
	min-height: 40px;
	background: #0f0;
	position: relative;
}
.close-popup {
	position: absolute;
	top: -12px;
	right: -12px;
	height: 25px;
	width: 25px;
	z-index: 2;
	cursor: pointer;
	background-color: #fff;
	border-radius: 100%;
	background-image: url(/images/close.png);
	background-size: 105% auto;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}
.popup-body {
	padding: 0;
	font-size: 14px;
	font-weight: 700;
	line-height: 24px;
}
.popup-bg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
}
.slide-popup {
	height: 564px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	//background-size: cover;
	background-size: contain;
	background-color: #F0F0F0;
}
.category-title {
    font-size: 12px;
    position: absolute;
    left: -28px;
    top: 560px;
    padding-left: 50px;
    width: 560px;
    transform-origin: 0 0;
    transform: rotate(-90deg);
    color: $primary;
    overflow: hidden;
    > div {
    	display: inline-block;
    	vertical-align: middle;
    	margin: 0 10px 0 0;
    	&:nth-child(3) ~ * {
    		display: none;
    	}
    }
}
@media(max-width: 767px) {
	.popup-wrapper {
		padding: 30px 30px;
	}
	.slide-popup {
		height: 350px;
	}
	.category-title {
		top: 350px;
		width: 350px;
		padding-left: 40px;
		> div {
	    	display: inline-block;
	    	vertical-align: middle;
	    	margin: 0 10px 0 0;
	    	&:nth-child(1) ~ * {
	    		display: none;
	    	}
	    }
	}
}



