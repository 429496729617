.main-header {
	overflow: hidden;
	padding: 37px 0;
	background: #fff;
	/*position: fixed;*/
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 10000;
	transition: 0.2s all linear 0s;
}
.header-scroll {
	.main-header {
		/*box-shadow: 0 1px $primary;*/
	}
}
.main-nav {
	float: right;
	padding: 23px 0;
	li {
		display: inline-block;
		vertical-align: top;
		margin-left: 19px;
		position: relative;
		&:last-child {
			a {
				background: #1F1F1F;
				color: #fff;
			}
			&:after {
				display: none;
			}
			&.active, &:hover {
				a {
					background: $primary;
				}
			}
		}
	}
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}
	a {
		font-family: 'Neutra Text';
		font-size: 16px;
		line-height: 17px;
		font-weight: 500;
		text-decoration: none;
		color: #7B7B7B;
		padding: 6px 14px;
		&:hover {
			color: $primary;
		}
	}
	.active {
		a {
			color: #000000;
		}
		&:after {
			content: "";
			background: $primary;
			width: 20px;
			height: 2px;
			position: absolute;
			top: -8px;
			left: 50%;
			margin-left: -10px;
		}
	}
}
.logo {
	float: left;
}

@media(max-width: 991px) {
	.logo {
		width: 200px;
	}
	.main-nav {
		padding: 17px 0;
	}
}
@media(max-width: 767px) {
	.logo {
		width: 150px;
	}
	.main-nav {
		padding: 17px 0;
	}
	.main-header {
		padding: 15px 0;
		overflow: visible;
	}
	.main-nav {
		width: 100%;
		position: absolute;
		top: 60px;
		left: 0;
		height: 100vh;
		display: none;
		padding: 60px 20px;
		background: #fff;
		border-bottom: 1px solid $primary;
		text-align: center;
		li {
			display: block;
			margin: 0 0 20px;
		}
	}
	.menu-btn {
		position: absolute;
		width: 40px;
		padding: 5px;
		right: 10px;
    	top: 11px;
		> div {
			background: $primary;
			height: 2px;
			margin: 7px 0;
			position: relative;
		}
	}
	.open-menu {
		.main-nav {
			display: block;
		}
		.menu-btn {
			> div {
				&:nth-child(1) {
					transform: rotate(45deg);
					top: 9px;
				}
				&:nth-child(2) {
					opacity: 0;
				}
				&:nth-child(3) {
					transform: rotate(-45deg);
					top: -9px;
				}
			}
		}
	}
}