.wrap-btn {
	margin: 42px 0 20px;
}
.btn {
	text-decoration: none;
	border-radius: 0px;
	font-size: 20px;
	line-height: 25px;
	padding: 10px 20px;
	font-weight: 500;
	min-width: 100px;
	font-family: 'Lato';
}
.btn-sm {
	font-size: 13px;
	line-height: 20px;
	padding: 4px 8px;
	min-width: 74px;
}