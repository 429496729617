$rem: (1 / 16) + 0rem;
@import "bootstrap";
@import "fonts/font";
@import "fonts/style";
@import "partials/text";
@import "partials/buttons";
@import "partials/form";
@import "partials/404";
@import "layout/slick";
@import "layout/slick-theme";
@import "layout/modals";
@import "layout/header";
@import "layout/main";
@import "layout/footer";