.form-group-radio {
	position: relative;
	label {
		font-weight: 700;
		padding-left: 30px;
		display: block;
		margin: 0;
	}
}

#js-submit-form-btn.disabled {
	cursor: not-allowed;
}
.form-group {
	margin-bottom: 37px;
}
.form-control {
	height: 47px;
	width: 100%;
	background: #fff;
	border: none;
	padding: 5px 0;
	color: #000;
	font-size: 18px;
	border-radius: 0;
	transition: 0.2s all 0s;
	border-bottom: 1px solid #000;
	&:focus {
		background-color: #fff;
		border-color: $primary;
		box-shadow: none;
		outline: none;
	}
	option {
		background: $primary;
		color: #fff;
	}
}
select.form-control {
	background-image: url(/images/select.svg);
	background-repeat: no-repeat;
	background-position: 100% 50%;
	background-size: 12px auto;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}


input[type="checkbox"] {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	height: 12px;
	width: 12px;
	border: 1px solid #202224;
	outline: none;
	cursor: pointer;
	display: inline-block;
	&:checked {
		background-image: url(/images/wrap-checkbox.png);
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: 80% auto;
	}
}

.label-form {
	font-size: 20px;
	margin-bottom: 5px;
}
.form-control-textarea {
	height: 384px;
	border: 1px solid #000;
	width: 100%;
	margin: 0;
	display: block;
	padding: 8px 15px;
	&:focus {
		border-color: $primary;
	}
}
.btn-form {
	text-align: right;
	.btn {
		height: 55px;
		font-size: 24px;
		border-radius: 28px;
		padding: 13px 50px;
	}
}
.wrap-textarea {
	position: relative;
	&:after {
		content: "";
		height: 30px;
		width: 30px;
		background: $primary;
		border-radius: 100%;
		position: absolute;
		bottom: -14px;
		left: -14px;
	}
}
.captcha-container {
	position: relative;

	.captcha_image {
		position: absolute;
		right: 0;
		top: -10px;
	}
}