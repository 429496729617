.main-footer {
	background: $secondary;
	color: #fff;
	font-size: 12px;
	padding: 57px 0;
	a {
		color: inherit;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
	.line {
		background: $primary;
		width: 100px;
		margin: 0 0 22px;
	}
}
.big-text {
	font-size: 14px;
	font-family: 'Neutra Text';
	font-weight: 700;
}
.title-one-footer {
	font-size: 12px;
	font-family: 'Neutra Text';
	font-weight: 500;
	margin-bottom: 13px;
}
.one-footer {
	margin-bottom: 50px;
}
.social-footer {
	list-style: none;
	padding: 0;
	margin: 0 0 10px;
	li {
		display: inline-block;
		vertical-align: top;
		margin-right: 13px;
	}
	a {
		display: block;
		font-size: 22px;
		&:hover {
			color: $primary;
			text-decoration: none;
		}
	}
}
.footer-bottom {
	text-align: center;
}
.illusio:before {
	content: "";
	display: inline-block;
	height: 8px;
	width: 8px;
	margin-right: 4px;
	background: $primary;
}