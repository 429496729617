html {
	font-size: 16px;
	height: 100%;
}
body {
	height: 100%;
	color: #1F1F1F;
	letter-spacing: 0.02em;
}
img {
	max-width: 100%;
}
a {
	color: inherit;
	&:hover, &:focus {
		color: inherit;
	}
}
*, *:before, *:after {
	outline: none;
}
.main-width {
	max-width: 1700px;
	padding: 0 30px;
	margin: auto;
}
.social-list {
	position: absolute;
	top: 50%;
	left: -170px;
	font-size: 14px;
	color: #7B7B7B;
	font-family: 'Neutra Text';
	font-weight: 700;
	list-style: none;
	padding: 0;
	margin: 0;
	width: 500px;
	text-align: center;
	transform: rotate(-90deg);
	z-index: 2;
	margin-top: 25px;
	li {
		display: inline-block;
		vertical-align: middle;
		margin: 0 12px;
	}
	a {
		text-decoration: none;
	}
}
.hero-section {
	position: relative;
	padding: 160px 0 85px;
	overflow: hidden;
	.block-style {
		max-height: 500px;
	}
}
.hero-img {
	height: 830px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	position: relative;
	z-index: 2;
	overflow: hidden;
	> div {
		position: absolute;
		top: 70px;
		right: 0;
		width: 140px;
		height: 400px;
		overflow: hidden;
		> div {
			background-blend-mode: multiply;
			background-image: inherit;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: 50% 50%;
			background-color: $primary;
			height: 100%;
			width: 100%;
			position: absolute;
			top: -70px;
			right: 0;
		}
	}
}
.row10 {
	margin-left: -10px;
	margin-right: -10px;
	> * {
		padding-left: 10px;
		padding-right: 10px;
	}
}
@media (min-width: 1700px) {
	.row55 {
		margin-left: -55px;
		margin-right: -55px;
		> * {
			padding-left: 55px;
			padding-right: 55px;
		}
	}
	.row77 {
		margin-left: -77px;
		margin-right: -77px;
		> * {
			padding-left: 77px;
			padding-right: 77px;
		}
	}
}
.section-style {
	padding: 70px 0;
	h2 {
		font-size: 52px;
		font-weight: 700;
		font-family: 'Neutra Text';
		margin-bottom: 60px;
	}
}
.section-style-padding {
	padding-bottom: 0;
}
.section-top {
	padding-top: 160px;
}
.block-style {
	margin-top: 70px;
	background: $primary;
	margin-left: -160px;
    padding: 50px 130px 50px 276px;
	font-size: 14px;
	line-height: 20px;
	color: #fff;
	h2 {
		font-family: 'Neutra Text';
		font-size: 52px;
		line-height: 70px;
		font-weight: 700;
		color: #fff;
		margin-bottom: 42px;
	}
	p {
		margin-bottom: 20px;
	}
}
.list-white {
	margin-bottom: 20px;
	&:not(.list-none):not(.slick-dots) li:before {
		background: #fff;
	}
}
.line {
	height: 5px;
	width: 132px;
	background: #fff;
	margin-bottom: 45px;
}
.line1 {
	background: $primary;
}
.line2 {
	background: #7B7B7B;
}
.link-style {
	margin: 53px 150px 53px 0;
	text-align: right;
	a {
		color: $primary;
		font-size: 16px;
		font-weight: 900;
		text-decoration: none;
		text-transform: uppercase;
		display: inline-block;
		&:hover {
			.arrow-link-style {
				&:after {
					opacity: 0;
				}
			}
		}
	}
}
.arrow-link-style {
	display: inline-block;
	vertical-align: top;
	height: 12px;
	width: 71px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: 100% auto;
	position: relative;
	margin: 6px 0 6px 44px;
	background-image: url(/images/arrow-right-long.svg);
	&:after {
		content: "";
		display: block;
		height: 45px;
		width: 45px;
		border-radius: 100%;
		background: $primary;
		opacity: 0.15;
		border-radius: 100%;
		position: absolute;
		top: -16px;
		right: -19px;
		z-index: 0;
		transition: 0.2s all linear 0s;
	}
}
.slide-nav {
	position: relative;
	margin: 0 8px;
	cursor: pointer;
	> div {
		padding-top: 100%;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: cover;
		position: relative;
		opacity: 0.2;
	}
	&:after {
		content: "";
		display: block;
		background: $secondary;
		opacity: 0.25;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: 1;
	}
}
.slick-current.slick-active {
	.slide-nav {
		> div {
			opacity: 1;
		}
		&:after {
			opacity: 0;
		}
	}
}
.slider-nav-wrap {
	position: absolute;
	bottom: 85px;
	left: 50%;
	width: 568px;
	margin-left: 257px;
}
.section-style-img {
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	color: #fff;
	@media(min-width: 1025px) {
		background-attachment: fixed;
	}
	h2 {
		color: #fff;
	}
	.arrow-link-style {
		background-image: url(/images/arrow-right-long-white.svg);
		&:after {
			background: #fff;
		}
	}
	.link-style a {
		color: #fff;
	}

}
.text-gray {
	color: #BAB9BA;
}
.img-style {
	padding-top: 119%;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	margin-bottom: 25px;
}
.one-block {
	font-size: 14px;
	margin-bottom: 40px;
	h4 {
		font-size: 28px;
		font-family: 'Neutra Text';
		font-weight: 700;
		margin: 0 0 16px;
	}
	p {
		margin-bottom: 24px;
	}
	.line {
		background: $primary;
		width: 160px;
		margin-bottom: 14px;
	}
}

.link-img-wrap {
	display: block;
	position: relative;
	text-decoration: none;
	margin-bottom: 20px;
	&:hover {
		.link-img-title {
			opacity: 1;
		}
		.link-img {
			background-blend-mode: multiply;
		}
		@media(min-width: 1025px) {
			.line-img-text {
				opacity: 1;
			}
		}
	}
}

@media(max-width: 1024px) {
	.line-img-text {
		display: none;
	}
}
.link-img {
	height: 340px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	background-color: $primary;
}
.link-img-title {
	font-size: 38px;
	font-weight: 700;
	color: #fff;
	font-family: 'Neutra Text';
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	text-align: center;
	left: 5px;
	right: 5px;
	opacity: 0;
}
.top {
	background-image: url(/images/top.png);
	background-repeat: no-repeat;
	background-position: 50% 50%;
	height: 85px;
	width: 85px;
	right: 20px;
	bottom: 20px;
	position: fixed;
	border-radius: 100%;
	background-color: $primary;
	cursor: pointer;
	z-index: 6;
	opacity: 0;
	transition: 0.2s all linear 0s;
	&:hover {
		background-color: $secondary;
	}
}
.body-scroll {
	.top {
		opacity: 1;
	}
}
.contact-index {
	margin: 0 -20px;
	font-size: 16px;
	line-height: 28px;
	font-size: 16px;
	line-height: 28px;
	.line {
		background: $primary;
		width: 220px;
		margin-bottom: 10px;
	}
	p {
		margin-bottom: 10px;
	}
	img {
		margin-bottom: 39px;
	}
}
.number {
	font-size: 22px;
	font-weight: 700;
}
.contact-index-text {
	font-size: 16px;
	line-height: 22px;
	margin: 80px 0 0;
	p {
		margin-bottom: 22px;
	}
}
.contact-index-btn {
	margin: 25px 0 0;
}
.map {
	position: relative;
	margin-left: 28%;
	margin-top: -355px;
	z-index: 0;
	margin-bottom: -70px;
	.map-iframe {
		height: 530px;
	}
}
.map-logo {
	position: absolute;
	left: 430px;
	top: 166px;
	width: 132px;
}
.map-container {
	background-image: url(/images/map.png);
	background-repeat: no-repeat;
	background-position: 50% 50%;
	height: 530px;
	position: relative;
	z-index: 0;
}
.map-logo-container {
	position: absolute;
	left: 50%;
	margin-left: 165px;
    top: 113px;
	width: 200px;
	font-weight: 900;
	font-size: 16px;
	line-height: 22px;
	text-align: center;
	.line {
		background: $primary;
		margin: 20px auto 15px;
	}
}
.position-wrap {
	position: relative;
	z-index: 1;
}
.right-column-contact {
	padding-bottom: 355px;
}
.filter-list {
	list-style: none;
	padding: 0;
	margin: 0 -15px 54px;
	overflow: hidden;
	li {
		float: left;
		position: relative;
		&:after {
			content: "";
			display: block;
			width: 1px;
			height: 25px;
			background: #000;
			position: absolute;
			top: 50%;
			margin-top: -12px;
			right: -1px;
		}
/*		&:hover {
			&:after {
				opacity: 0;
			}
		}*/
		&:last-child {
			&:after {
				opacity: 0;
			}
		}
	}
	a {
		font-size: 16px;
		color: #7B7B7B;
		text-decoration: none;
		padding: 12px 35px;
		display: block;
		&:hover {
			color: $primary;
		}
	}
	.active {
		z-index: 2;
		&:after {
			opacity: 0;
		}
		a {
			background: $primary;
			color: #fff;
		}
	}
}
.line-img-text {
	position: absolute;
	bottom: 80px;
	left: 80px;
	right: 80px;
	font-size: 16px;
	color: #fff;
	font-weight: 700;
	z-index: 2;
	opacity: 0;
	h3 {
		font-family: 'Neutra Text';
		font-size: 52px;
		font-weight: 700;
		margin: 0 0 5px;
	}
	h4 {
		font-size: 20px;
		font-weight: 700;
		margin: 0 0 20px;
	}
	p {
		margin-bottom: 35px;
	}
	.line {
		margin-bottom: 20px;
	}
	.link-style {
		margin: 0;
		text-align: left;
	}
	.arrow-link-style {
		background-image: url(/images/arrow-right-long-white.svg);
		&:after {
			background: #fff;
		}
	}
	.link-style a {
		color: #fff;
	}
}
.link-img-big {
	.link-img {
		height: 637px;
	}
}
.scroller-wrap {
	margin: 40px 0;
	text-align: center;
}
.scroller {
	text-decoration: none;
	height: 45px;
	width: 45px;
	border-radius: 100%;
	color: #fff;
	display: block;
	margin: 0 auto 18px;
	background: $primary;
	font-size: 22px;
	text-align: center;
	line-height: 50px;
	&:hover {
		color: #fff;
		background: $secondary;
	}
}
.hero-img2 {
	height: 590px;
	margin-left: -150px;
}
.quote {
	max-width: 1132px;
	padding: 0 150px;
	margin: auto;
	position: relative;
	p {
		font-size: 20px;
		text-align: center;
		font-weight: 300;
		line-height: 28px;
		font-style: italic;
		margin-bottom: 0;
	}
}
.quote-top {
	font-size: 140px;
	color: $primary;
	font-family: 'Neutra Text';
	font-weight: 700;
	position: absolute;
	top: -60px;
	line-height: 1;
	left: 0;
}
.quote-bottom {
	font-size: 140px;
	line-height: 1;
	color: $primary;
	font-family: 'Neutra Text';
	font-weight: 700;
	position: absolute;
	bottom: -110px;
	right: 0;
}
.wrap-company {
	margin: 140px 0;
	.row {
		> * {
			position: relative;
			&:after {
				content: "";
				display: block;
				height: 40px;
				width: 2px;
				background: #1F1F1F;
				position: absolute;
				top: 50%;
				margin-top: -20px;
				right: -1px;
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
		}
	}
}
.company {
	height: 125px;
	margin: 0 auto 10px;
	width: 250px;
	max-width: 100%;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: 50% 50%;
}
.champs {
	font-size: 12px;
	font-weight: 300;
	margin: 30px 0 0;
}
.contact-info {
	margin-top: 110px;
	margin-bottom: 110px;
	> div {
		display: inline-block;
		vertical-align: middle;
		padding-right: 10px;
		margin-bottom: 10px;
		img {
			display: inline-block;
			vertical-align: middle;
		}
		span {
			display: inline-block;
			vertical-align: middle;
			font-size: 18px;
			margin-left: 17px;
			min-width: 240px;
		}
		&:before {
			content: "";
			display: inline-block;
			vertical-align: middle;
			height: 10px;
			width: 10px;
			border-radius: 100%;
			background: $primary;
			margin-right: 10px;
		}
	}
}
@media (max-width: 1700px) {
	.slider-nav-wrap {
		margin-left: 0;
		left: auto;
		right: 22px;
		width: 500px;
	}
}
@media (max-width: 1400px) {
	.slider-nav-wrap {
		width: 35%;
	}
	.block-style {
		padding-left: 175px;
		padding-right: 30px;
	}
	.link-style {
		margin-right: 30px;
	}
}
@media (max-width: 991px) {
	.hero-section .block-style {
		max-height: 10000px;
	}
	.hero-section {
		padding-bottom: 160px;
	}
	.slider-nav-wrap {
		right: auto;
		left: 22px;
		bottom: 60px;
	}
	.block-style h2 {
		font-size: 36px;
		line-height: 40px;
	}
	.section-style h2 {
		font-size: 36px;
		line-height: 40px;
	}
	.arrow-link-style {
		margin-left: 20px;
	}
	.right-column-contact {
		padding-bottom: 0;
	}
	.map {
		margin: 0;
	}
}
@media (max-width: 767px) {
	.main-width {
		padding: 0 15px;
	}
	.illusio {
		white-space: nowrap;
	}
	.logo-footer {
		margin-bottom: 30px;
	}
	.link-img {
		height: 250px;
	}
	.contact-index {
		width: 400px;
		max-width: 100%;
		margin: 0 0 20px;
	}
	.hero-section {
		padding-top: 100px;
		padding-bottom: 0;
	}
	.hero-img {
		height: 300px;
		> div {
			display: none;
		}
	}
	.slider-nav-wrap {
		width: 320px;
		left: 50%;
		right: auto;
		bottom: auto;
		top: 408px;
		margin-left: -160px;
	}
	.block-style {
		margin-top: 0;
	}
	.hero-section .main-width {
		padding: 0;
	}
	.img-style {
		padding-top: 250px;
	}
	.contact-info > div span {
		min-width: 200px;
	}
	.link-img-wrap {
		margin-left: -15px;
		margin-right: -15px;
	}
	.link-img-big .link-img {
		height: 300px;
	}
	.line-img-text {
		left: 10px;
		right: 10px;
		bottom: 15px;
	}
	.line-img-text p {
		margin-bottom: 20px;
	}
	.filter-list a {
		padding: 5px 12px;
		margin-bottom: 5px;
	}
	.section-top {
		padding-top: 100px;
	}
	.wrap-company {
		margin: 0;
	}
	.wrap-company .row > *:after {
		display: none;
	}
	.quote {
		padding: 0 50px;
	}
	.top {
		height: 40px;
		width: 40px;
		right: 10px;
		bottom: 10px;
		background-size: 10px auto;
	}
	.hero-img2 {
		margin-left: 0;
	}
	.block-style {
		margin: 0;
		padding: 20px 15px;
	}
}
.social-popup {
	list-style: none;
	padding: 0;
	top: -70px;
	position: relative;
	margin: 0;
	font-size: 22px;
	text-align: right;
	li {
		display: inline-block;
		vertical-align: top;
		margin-left: 13px;
	}
	a {
		text-decoration: none;
		color: #BAB9BA;
		&:hover {
			color: $primary;
		}
	}
}
.slider {
	padding-bottom: 0;
}
.popup-body {
	.line1 {
		margin-bottom: 35px;
	}
	.line2 {
		margin-top: 30px;
	}
	.link-style {
		margin-right: 30px;
	}
}
.slider-wrap {
	position: relative;
	margin-bottom: 13px;
}
.title-popup {
	min-height: 72px;
	background: $primary;
	font-size: 38px;
	color: #fff;
	font-family: 'Neutra Text';
	font-weight: 700;
	padding: 20px 25px 20px 138px;
	left: -138px;
	bottom: 36px;
	display: inline-block;
	vertical-align: top;
	max-width: 100%;
	position: relative;
	.text-primary {
		color: #000 !important;
	}
}
.category-none {
	display: none;
}
@media (min-width: 768px) {
	.project-wrap-item {
		.col {
			-webkit-box-flex: 0;
			-ms-flex: 0 0 33.33333%;
			flex: 0 0 33.33333%;
			max-width: 33.33333%;
			&:nth-child(4n + 1), &:nth-child(4n + 4) {
				-webkit-box-flex: 0;
				-ms-flex: 0 0 66.66667%;
				flex: 0 0 66.66667%;
				max-width: 66.66667%;
			}
		}
	}
/*	.project2-list {
		.col {
			-webkit-box-flex: 0;
			-ms-flex: 0 0 33.33333%;
			flex: 0 0 33.33333%;
			max-width: 33.33333%;
			&:nth-child(5n + 2), &:nth-child(5n + 3) {
				-webkit-box-flex: 0;
				-ms-flex: 0 0 66.66667%;
				flex: 0 0 66.66667%;
				max-width: 66.66667%;
			}
			&:nth-child(5n + 5) {
				-webkit-box-flex: 0;
				-ms-flex: 0 0 100%;
				flex: 0 0 100%;
				max-width: 100%;
			}
		}
	}*/
}
@media (max-width: 767px) {
	.project-wrap-item, .project2-list {
		.col {
			-webkit-box-flex: 0;
			-ms-flex: 0 0 100%;
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
	.social-popup {
	    margin: 49px 0 0;
	    text-align: left;
	}
	.title-popup {
		min-height: 64px;
		left: -30px;
		padding-left: 30px;
		font-size: 24px;
		line-height: 1;
	}
}

.map-iframe {
	height: 675px;
	border-radius:300px;
	overflow: hidden;
	&:after {
		content: "";
		box-shadow: 0 0 100px 150px #fff inset;
		display: block;
		position: absolute;
		top: -10px;
		left: -10px;
		bottom: -10px;
		right: -10px;
		border-radius:300px;
		z-index: 3;
	}

/*	&:before {
		content: "";
		box-shadow: 0 0 100px 100px #fff;
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		height: 0;
		width: 100%;
		z-index: 3;
	}*/
}
/*.map-iframe-wrap {
	position: relative;
	&:after {
		content: "";
		box-shadow: 0 0 100px 100px #fff;
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		width: 0;
		z-index: 3;
	}
	&:before {
		content: "";
		box-shadow: 0 0 100px 100px #fff;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 0;
		z-index: 3;
	}
}*/
.gm-style-mtc {
	display: none;
}
.gm-bundled-control {
	display: none;
}
.gm-fullscreen-control {
	display: none;
}
.slider_company {
	.slick-slide {
		position: relative;
		&:after {
		    content: "";
		    display: block;
		    height: 40px;
		    width: 2px;
		    background: #1F1F1F;
		    position: absolute;
		    top: 50%;
		    margin-top: -20px;
		    right: -1px;
    	}
    }
    &:after {
    	content: "";
    	position: absolute;
    	top: 0;
    	left: 0;
    	bottom: 0;
    	width: 2px;
    	background: #fff;
    	z-index: 2;
    }
    &:before {
    	content: "";
    	position: absolute;
    	top: 0;
    	right: 0;
    	bottom: 0;
    	width: 2px;
    	background: #fff;
    	z-index: 2;
    }
}
@media(max-width: 767px) {
	.map-iframe {
		height: 300px;
		border-radius:50px;
		overflow: hidden;
		&:after {
			content: "";
			box-shadow: 0 0 20px 40px #fff inset;
			display: block;
			position: absolute;
			top: -10px;
			left: -10px;
			bottom: -10px;
			right: -10px;
			border-radius:50px;
			z-index: 3;
		}
	}
	.map {
		.map-iframe {
			height: 300px;
		}
	}
	.arrow-link-style {
		width: 40px;
		position: absolute;
		right: 0;
	}
	.arrow-link-style:after {
		height: 30px;
		width: 30px;
		top: -9px;
		right: -12px;
		position: absolute;
	}
	.link-style {
		padding-right: 60px;
		position: relative;
	}
	.slider-for {
		.block-style {
			padding-top: 100px;
		}
	}
	.section-style-padding-mobile {
		padding-bottom: 0;
	}

}

@media(max-width: 1780px) and (min-width: 1201px){
	.hero-section {
		padding-left: 100px;
	}
}
@media(max-width: 1200px){	
	.social-list {
		display: none;
	}
}
.map-contact {
	position: relative;
	&:before {
		content: "";
		width: 50px;
		height: 2px;
		background: $primary;
		z-index: 10;
		transform: rotate(-45deg);
		transform-origin: 100% 0;
		position: absolute;
		top: 50px;
		left: 50%;
		margin-left: 760px;
		@media(max-width: 1650px) {
			margin-left: 0;
			left: auto;
			top: 20px;
			right: 30px;
		}
	}
}
.section-style-bg {
	background-image: url(/images/bg-company.png);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: 0 0;
}