b {
	font-weight: 700;
}
strong {
	font-weight: 700;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	color: inherit;
	font-weight: 700;
	margin-bottom: 1.2em;
}
h1, .h1 {
	line-height: 1.111111;
	margin-bottom: 26px;
}

p, ul, ol {
	margin-bottom: 10px;
}
.text-lg {
	font-size: 24px; // 24
	line-height: 30px;
}
.text-md {
	font-size: 18px;
	line-height: 30px;
}
.text-sm {
	font-size: 14px; // 14
	line-height: 24px;
}

ul:not(.list-none):not(.slick-dots) {
	list-style: none;
	padding-left: 17px;
	li {
		/*@extend .icon-chevron-right;*/
		position: relative;
		margin-bottom: 3px;
		&:before {
			content: "";
			position: absolute;
			height: 5px;
			width: 5px;
			border-radius: 100%;
			top: 9px;
			left: -17px;
			background: $primary;
			border-radius: 100%;
		}
	}
}
ol:not(.list-none) {
	list-style: none;
	counter-reset: section;
	padding-left: 30px;
	li {
		/*@extend .icon-chevron-right;*/
		position: relative;
		margin-bottom: 1px;
		&:before {
			counter-increment: section;
			content: counter(section); 
			display: inline-block;
			position: absolute;
			top: 6px;
			left: -30px;
			font-size: 12px;
			font-weight: 400;
			background: #C1C2BC;
			border-radius: 100%;
			color: #fff;
			height: 20px;
			width: 20px;
			text-align: center;
			line-height: 20px;
		}
	}
}
.weight-400 {
	font-weight: 400;
}
.weight-500 {
	font-weight: 500;
}
.weight-700 {
	font-weight: 700;
}
.margin-none {
	margin-bottom: 0 !important;
}
.margin-style {
	margin-bottom: 100px !important;
	&:last-child {
		margin-bottom: 0 !important;
	}
}

.sub-title {
	margin-bottom: 9px;
	font-weight: 400;
}